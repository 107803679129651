import { AdminMainContent, AdminCategoryContainer, AdminCategoryTitle, AdminSettingContainer, AdminSettingText, AdminSettingSelectBox, SmallTextSpan, AdminSettingTextarea, AdminButton } from "./AdminPage-styling";
//import AddUser from "./AdminComponents/AddUser";
import { Organization, Inbox, User } from "../Types";
import { useRef, useEffect, useState } from "react";
import { ax } from "../Utils";
import AddInbox from "./AdminComponents/FeedbackTab/AddInbox";
import AddWebsite from './AdminComponents/Sources/Websites/AddWebsite';
import AddDocument from "./AdminComponents/AddDocument";
import { FaLanguage } from "react-icons/fa6";
import Swal from "sweetalert2";
import CompanyInboxList from "./AdminComponents/FeedbackTab/EmailInboxList";
import { useQueries, useQueryClient, useMutation } from "@tanstack/react-query";
import { useCurrentUser } from "../Queries/useCurrentUser";
import ChangeDescription from "./AdminComponents/UnitInfo";
import { useOrganization } from "../Queries/useOrganization";
import { useAssistantLanguages } from "../Queries/useAssistantLanguages";
import { useInterfaceLanguages } from "../Queries/useInterfaceLanguages";
import WebPageList from "./AdminComponents/Sources/Websites/WebPageList";
import WebScrapeConfigTable from "./AdminComponents/Sources/Websites/WebScrapeConfigTable";
import AssistantConfigPopUp from "./AdminComponents/AssistantConfigPopUp";
import { StandardBase } from "../Components/StandardBase";
import { UsersTable } from "./AdminComponents/UsersTable";
import { Tabs, Tab, Box } from '@mui/material';
import { People, Language as LanguageIcon, Email, Business, Language, Web, Description, Settings, Chat, Assistant, SmartToyOutlined } from '@mui/icons-material';
import { useCurrentTabAdmin } from "../Queries/useCurrentTabAdmin";
import ChatbotConfigTab from "./AdminComponents/ChatbotConfig/ChatbotConfigTab";
import AssistantConfig from "./AdminComponents/AssistantConfig/AssistantConfig";
import AdminFeedbackTab from "./AdminComponents/FeedbackTab/AdminFeedbackTab";
import { useNavigate, useParams } from "react-router-dom";
import { useUserMeta } from "../Queries/useUserMeta";
import UnitInfo from "./AdminComponents/UnitInfo";
import FilesTable from './AdminComponents/Sources/Files/FilesTable';
import CustomDataModelsTable from './AdminComponents/Sources/DataModels/CustomDataModelsTable';

const AdminPage = () => {

  const [assistantLanguageId, setAssistantLanguageId] = useState<string>('');
  const [interfaceLanguageId, setInterfaceLanguageId] = useState<string>('');
  const [showAssistantConfigPopUp, setShowAssistantConfigPopUp] = useState(false);
  
  const queryClient = useQueryClient();
  
  const { data: userMeta } = useUserMeta();
  const { data: organization, ...organizationQuery } = useOrganization(userMeta?.org_id);
  const { data: assistantLanguages, ...assistantLanguagesQuery } = useAssistantLanguages();
  const { data: interfaceLanguages, ...interfaceLanguagesQuery } = useInterfaceLanguages();
  const { currentTab, updateCurrentTab } = useCurrentTabAdmin();
  
  const { tab } = useParams();
  const navigate = useNavigate();

    // Add this mapping object for tab names to indices
  const tabMapping = {
    users: 0,
    languages: 1,
    feedback: 2,
    sources: 3,
    documents: 4,
    assistant: 5,
    chatbot: 6
  } as const;

  // Add reverse mapping for indices to tab names
  const reverseTabMapping = {
    0: 'users',
    1: 'languages',
    2: 'feedback',
    3: 'sources',
    4: 'documents',
    5: 'assistant',
    6: 'chatbot'
  } as const;

  // Add sub-tabs for different source types
  const sourceSubTabs = {
    websites: 'websites',
    files: 'files',
    templates: 'templates',
    dataModels: 'dataModels'
  } as const;

  const reverseSourceSubTabs = {
    websites: 0,
    files: 1,
    templates: 2,
    dataModels: 3
  } as const;

  const [currentSourceSubTab, setCurrentSourceSubTab] = useState<keyof typeof reverseSourceSubTabs>('websites');

  const handleSourceSubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const subTabName = Object.keys(reverseSourceSubTabs)[newValue] as keyof typeof reverseSourceSubTabs;
    setCurrentSourceSubTab(subTabName);
    navigate(`/admin/sources/${subTabName}`, { replace: true });
  };

  useEffect(() => {
    if (tab === 'sources' && !Object.keys(reverseSourceSubTabs).includes(currentSourceSubTab)) {
      navigate(`/admin/sources/${currentSourceSubTab}`, { replace: true });
    }
  }, [tab, currentSourceSubTab]);

  useEffect(() => {
    if (tab && tabMapping[tab as keyof typeof tabMapping] !== currentTab) {
      updateCurrentTab(tabMapping[tab as keyof typeof tabMapping]);
    }
  }, [tab]);

  useEffect(() => {
    if (!tab) {
      navigate(`/admin/${reverseTabMapping[currentTab as keyof typeof reverseTabMapping]}`, { replace: true });
    }
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    updateCurrentTab(newValue);
    navigate(`/admin/${reverseTabMapping[newValue as keyof typeof reverseTabMapping]}`, { replace: true });
  };




  const organizationMutation = useMutation({
    mutationFn: (requestBody: any) => {
      return ax.patch('/organization', requestBody);
    },
    onSuccess: (data, requestBody) => {
      queryClient.setQueryData(['organization', { obj_id: userMeta?.org_id }], data);
      queryClient.invalidateQueries({ queryKey: ['organization', { obj_id: userMeta?.org_id }] });
      if (requestBody.business_description) {
        Swal.fire({
          title: "Företagsbeskrivning uppdaterad!",
          icon: "success"
        });
      }
      else if (requestBody.default_assistant_language_id || requestBody.default_interface_language_id) {
        Swal.fire({
          title: "Språk uppdaterade!",
          icon: "success"
        });
      }
    },
    onError: (error, requestBody) => {
      if (requestBody.business_description) {
        Swal.fire({
          title: "Uppdatering av företagsbeskrivning misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
      else if (requestBody.default_assistant_language_id || requestBody.default_interface_language_id) {
        Swal.fire({
          title: "Uppdatering av språk misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
    }
  });

  const updateDefaultLanguages = async () => {
    if (organization && (assistantLanguageId !== organization.default_assistant_language_id || interfaceLanguageId !== organization.default_interface_language_id)) {
      const requestBody: any = {
        "id": organization.id,
        "default_assistant_language_id": assistantLanguageId,
        "default_interface_language_id": interfaceLanguageId,
      };
      console.log(requestBody);

      organizationMutation.mutate(requestBody);
    }
  };

  const toggleAssistantConfigPopUp = () => {
    setShowAssistantConfigPopUp(!showAssistantConfigPopUp);
  }





  return (
    <StandardBase>
      <AdminMainContent>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={currentTab} 
          onChange={handleTabChange} 
          variant="scrollable" 
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#212121',
            },
            '& .MuiTab-root': {
              color: '#212121',
              '&.Mui-selected': {
                color: '#212121',
              },
            },
          }}
        >
          <Tab icon={<People />} label="Användare" />
          <Tab icon={<Settings />} label="Allmänt" />
          <Tab icon={<Email />} label="Feedback" />
          <Tab icon={<Web />} label="Källor" />
          <Tab icon={<Description />} label="Texter" /> {/* Baka in i källor */}
          <Tab icon={<Assistant />} label="Assistenten" />
          <Tab icon={<SmartToyOutlined />} label="Kundchatbot" />
        </Tabs>
      </Box>
        {currentTab === 0 ? (
        <AdminCategoryContainer>
          <UsersTable />
        </AdminCategoryContainer>
        ) : currentTab === 1 ? (
        <AdminCategoryContainer>
          <AdminCategoryTitle>Språk <FaLanguage/></AdminCategoryTitle>
          <AdminSettingContainer>
            <AdminSettingText>Hemsidans språk</AdminSettingText>
            <AdminSettingSelectBox
              defaultValue={organization?.default_interface_language_id}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setInterfaceLanguageId(event.target.value)}
            >
              {interfaceLanguages?.map((language) => (
                <option key={language.id} value={language.id}>{language.name}</option>
              ))}
            </AdminSettingSelectBox>
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Assistentens språk</AdminSettingText>
            <AdminSettingSelectBox
              defaultValue={organization?.default_assistant_language_id}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setAssistantLanguageId(event.target.value)}
            >
              {assistantLanguages?.map((language) => (
                <option key={language.id} value={language.id}>{language.name}</option>
              ))}
            </AdminSettingSelectBox>
          </AdminSettingContainer>
          <AdminButton onClick={updateDefaultLanguages}>
            Spara
          </AdminButton>
          <UnitInfo />
        </AdminCategoryContainer>
        ) : currentTab === 2 ? (
        <AdminCategoryContainer>
          <AdminFeedbackTab />
        </AdminCategoryContainer>
        ) : currentTab === 3 ? (
        <AdminCategoryContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs 
              value={reverseSourceSubTabs[currentSourceSubTab]} 
              onChange={handleSourceSubTabChange} 
              variant="scrollable" 
              scrollButtons="auto"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#212121',
                },
                '& .MuiTab-root': {
                  color: '#212121',
                  '&.Mui-selected': {
                    color: '#212121',
                  },
                },
              }}
            >
              <Tab label="Hemsidor" />
              <Tab label="Filer" />
              <Tab label="Mallar" />
              <Tab label="Datamodeller" />
            </Tabs>
          </Box>
          {currentSourceSubTab === 'websites' && (
            <>
              <WebScrapeConfigTable />
              <AddWebsite />
              <AdminSettingContainer>
                <WebPageList />
              </AdminSettingContainer>
            </>
          )}
          {currentSourceSubTab === 'files' && <FilesTable />}
          {currentSourceSubTab === 'templates' && <div>Templates content goes here</div>}
          {currentSourceSubTab === 'dataModels' && <CustomDataModelsTable />}
        </AdminCategoryContainer>
        ) : currentTab === 4 ? (
        <AdminCategoryContainer>
          <AdminCategoryTitle>Lägg till texter</AdminCategoryTitle>
          <AddDocument />
        </AdminCategoryContainer>
        ) : currentTab === 5 ? (
        <AdminCategoryContainer>
          <AssistantConfig />
        </AdminCategoryContainer>
        ) : currentTab === 6 ? (
        <AdminCategoryContainer>
          <ChatbotConfigTab />
        </AdminCategoryContainer>
        ) : null}
      </AdminMainContent>
      {showAssistantConfigPopUp && (
        <AssistantConfigPopUp
          toggleAssistantConfigPopUp={toggleAssistantConfigPopUp}
        />
      )}
    </StandardBase>
  );
};

export default AdminPage;